import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../AppConstants'; import { Title } from '@angular/platform-browser';
import { AddcircleComponent } from './addcircle/addcircle.component';
import { Router } from '@angular/router';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.css']
})
export class CircleComponent implements OnInit {

  ngOnInit() {
  }

  displayedColumns = ['index', 'name', 'organization', 'action'];
  dataSource = new MatTableDataSource();
  loogedUserRole: string;
  loogedUOrganization: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  response = { code: null, responseData: null, message: null };
  constructor(private titleService: Title, private router: Router, private http: HttpClient, public dialog: MatDialog, private alerts: AlertsService, private _snackBar: MatSnackBar,
  ) {
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    this.getAllCircles();
    this.titleService.setTitle('Circles');
  }

  getAllCircles() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_CIRCLES)
      .subscribe((data) => {
        this.dataSource.data = [];
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logCircles = [];
        this.response.responseData.forEach(r => {

          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.organization.name) {
              logCircles.push(r);
            }
          }
        });

        this.dataSource.data = logCircles;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addCircle(): void {
    const dialogRef = this.dialog.open(AddcircleComponent, {
      width: '640px', disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllCircles();
    });
  }

  editCircle(event, params) {
    event.stopPropagation();
    const payload = params;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "640px";
    dialogConfig.data = Object.assign({}, payload);

    const dialogRef = this.dialog.open(
      AddcircleComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      this.getAllCircles();
    });
  }

  deleteCircle(x) {
    var r = confirm("Are you sure want to delete Circle ? ");
    if (r == true) {
      this.http.get(AppConstants.BASE_URL + AppConstants.DELETE_CIRCLE + "?id=" + x)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Circle Deleted Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.getAllCircles();
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }
    else {
      // txt = "You pressed Cancel!";
    }
  }

}
