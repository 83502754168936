import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../AppConstants'; import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  response = { code: null, responseData: null, message: null };

  loginForm: FormGroup;
  constructor(private titleService: Title, private router: Router, private formBuilder: FormBuilder, private alerts: AlertsService, private _snackBar: MatSnackBar, private http: HttpClient) {
    this.loginForm = this.formBuilder.group({
      userName: new FormControl('', [Validators.required]),
      passWord: new FormControl('', [Validators.required])
    });
    this.titleService.setTitle('Login');
  }

  onSubmit() {
    // console.log(this.loginForm.value);
    let obj = this.loginForm.value;
    this.http.post(AppConstants.BASE_URL + AppConstants.LOGIN, this.loginForm.value)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        if (this.response.code == 200) {
          localStorage.setItem('userId', btoa(this.response.responseData.id));
          localStorage.setItem('name', btoa(this.response.responseData.userName));
          localStorage.setItem('roleName', btoa(this.response.responseData.role.roleName));
          if (this.response.responseData.organization != null)
            localStorage.setItem('organizationName', btoa(this.response.responseData.organization.name));
          if (this.response.responseData.role.roleName == 'SuperAdmin')
            this.router.navigateByUrl('/user');
          else
            this.router.navigateByUrl('/dashboard');
          // this.router.navigateByUrl('/trackingdetails');
        }
        else if (this.response.code == 201) {
          this._snackBar.open('Invalid User Name !', 'OK', {
            duration: 2000, verticalPosition: "bottom"
          });
        }
        else if (this.response.code == 203) {
          this._snackBar.open('Invalid Password !', 'OK', {
            duration: 2000, verticalPosition: "bottom"
          });
        }
        else {
          this._snackBar.open('Server is Down!', 'OK', {
            duration: 2000, verticalPosition: "bottom"
          });
        }
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "bottom"
            });
        });
  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    // this.alerts.setMessage('All the fields are required', 'error');
    // this.alerts.setMessage('Configurations saved successfully!', 'success');
    // this.alerts.setMessage('Please save all the changes before closing', 'warn');
  }

}
