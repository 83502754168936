import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../AppConstants'; import { Title } from '@angular/platform-browser';
import { AddorganizationComponent } from './addorganization/addorganization.component';
import { Router } from '@angular/router';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {
  retrievedImage: string;
  loogedUserRole: string;
  loogedUOrganization: string;

  ngOnInit() {
  }

  displayedColumns = ['index', 'name', 'pic', 'action'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  response = { code: null, responseData: null, message: null };
  constructor(private titleService: Title, private router: Router, private http: HttpClient, public dialog: MatDialog, private alerts: AlertsService, private _snackBar: MatSnackBar,
  ) {
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    this.getAllOrganizations();
    this.titleService.setTitle('Organizations');
  }

  getAllOrganizations() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_ORGANIZATIONS)
      .subscribe((data) => {
        this.dataSource.data = [];
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));

        let logOrganizations = [];
        this.response.responseData.forEach(r => {
          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.name) {
              logOrganizations.push(r);
            }
          } else {
            logOrganizations.push(r);
          }
        });

        this.dataSource.data = logOrganizations;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  downloadKML(r) {
    // alert(r);
    let content = atob(r.picByte);
    var file = new Blob([content], { type: 'application/vnd.google-earth.kml+xml' });
    var url = URL.createObjectURL(file);
    var fileLink = document.createElement('a');
    fileLink.href = url;
    fileLink.download = r.name + '.kml';
    fileLink.click();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addOrganization(): void {
    const dialogRef = this.dialog.open(AddorganizationComponent, {
      width: '640px', disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllOrganizations();
    });
  }

  editOrganization(event, params) {
    event.stopPropagation();
    const payload = params;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "640px";
    dialogConfig.data = Object.assign({}, payload);

    const dialogRef = this.dialog.open(
      AddorganizationComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      this.getAllOrganizations();
    });
  }

  deleteOrganization(x) {
    var r = confirm("Are you sure want to delete Organization Type ? ");
    if (r == true) {
      this.http.get(AppConstants.BASE_URL + AppConstants.DELETE_ORGANIZATION + "?id=" + x)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Organization Type Deleted Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.getAllOrganizations();
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }
    else {
      // txt = "You pressed Cancel!";
    }
  }

}