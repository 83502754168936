import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../AppConstants'; import { Title } from '@angular/platform-browser';
import { AdddivisionComponent } from './adddivision/adddivision.component';
import { Router } from '@angular/router';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css']
})
export class DivisionComponent implements OnInit {

  ngOnInit() {
  }

  displayedColumns = ['index', 'name', 'circleName', 'organization', 'action'];
  dataSource = new MatTableDataSource();
  loogedUserRole: string;
  loogedUOrganization: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  response = { code: null, responseData: null, message: null };
  constructor(private titleService: Title, private router: Router, private http: HttpClient, public dialog: MatDialog, private alerts: AlertsService, private _snackBar: MatSnackBar,
  ) {
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    this.getAllDivisions();
    this.titleService.setTitle('Divisions');
  }

  getAllDivisions() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_DIVISIONS)
      .subscribe((data) => {
        this.dataSource.data = [];
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        let logDivisions = [];
        this.response.responseData.forEach(r => {

          if (this.loogedUserRole == 'Admin') {
            if (this.loogedUOrganization == r.organization.name) {
              logDivisions.push(r);
            }
          }
        });


        this.dataSource.data = logDivisions;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  addDivision(): void {
    const dialogRef = this.dialog.open(AdddivisionComponent, {
      width: '640px', disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllDivisions();
    });
  }

  editDivision(event, params) {
    event.stopPropagation();
    const payload = params;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "640px";
    dialogConfig.data = Object.assign({}, payload);

    const dialogRef = this.dialog.open(
      AdddivisionComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      this.getAllDivisions();
    });
  }

  deleteDivision(x) {
    var r = confirm("Are you sure want to delete Division ? ");
    if (r == true) {
      this.http.get(AppConstants.BASE_URL + AppConstants.DELETE_DIVISION + "?id=" + x)
        .subscribe((data) => {
          // console.log(data);
          this.response = JSON.parse(JSON.stringify(data));
          if (this.response.code == 200) {
            this._snackBar.open('Division Deleted Successfully!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
            this.getAllDivisions();
          }
          else {
            this._snackBar.open('Server Error!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
          }
        });
    }
    else {
      // txt = "You pressed Cancel!";
    }
  }

}