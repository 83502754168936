import { Component, OnInit, Sanitizer, SecurityContext } from '@angular/core';
import { AppConstants } from '../AppConstants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { AlertsService } from 'angular-alert-module'; import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public kmlURL;
  response = { code: null, responseData: null, message: null };
  loogedUserRole: string;
  loogedUOrganization: string;
  loader = "false";
  animalnames: any;
  trackers: any;
  date: Date;

  constructor(private http: HttpClient, private router: Router, private titleService: Title, private alerts: AlertsService, private _snackBar: MatSnackBar, private sanitizer: DomSanitizer) {
    // this.getAllTrackers();
    // this.getOrganizationKML();
    this.getTrip();
    this.date = new Date();
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
    this.kmlURL = AppConstants.KML_URL + this.loogedUOrganization + ".kml";
    this.titleService.setTitle('Dasboard');
  }

  ngOnInit() {
  }

  getTrip() {
    this.loader = "true";
    let dte = new Date();
    dte.setHours(0)
    dte.setMinutes(0)
    dte.setSeconds(0)
    // console.log(dte.getTime());
    let dte2 = new Date();
    dte2.setHours(23)
    dte2.setMinutes(59)
    dte2.setSeconds(59)
    let trackingObj = { startDate: dte.getTime(), endDate: dte2.getTime(), trackers: null }
    localStorage.setItem('formdetails', JSON.stringify(trackingObj));
    // console.log(trackingObj)
    this.http.post(AppConstants.BASE_URL + AppConstants.ALL_TRACKINGS_LIVE, trackingObj)
      .subscribe((data) => {
        this.loader = "false";
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));

        let logTripDetails = [];
        this.response.responseData.forEach(r => {
          if (this.loogedUOrganization == r.organization.name) {
            logTripDetails.push(r);
          }
        });
        if (this.loogedUserRole == 'SuperAdmin')
          this.showMap(this.response.responseData);
        else
          this.showMap(logTripDetails);
      });
  }

  getDate = function (num) {
    var d = new Date(num);
    return d.toLocaleDateString() // 7/25/2016
  }
  getTime = function (num) {
    var d = new Date(num);
    return d.toLocaleTimeString();
  }
  getDateTime = function (num) {
    var d = new Date(num);
    return d.toLocaleString();
  }

  getDistance = function (num) {
    num = num / 1000
    num = num.toFixed(2)
    return num;
  }

  getAllAnimalNames() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_ANIMALNAMES)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        this.animalnames = this.response.responseData;
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  getAllTrackers() {
    this.http.get<any[]>(AppConstants.BASE_URL + AppConstants.ALL_TRACKERS)
      .subscribe((data) => {
        // console.log(data);
        this.response = JSON.parse(JSON.stringify(data));
        this.trackers = this.response.responseData;
        this.locationArray = new Map<string, number>();
        for (var i = 0; i < this.trackers.length; i++) {
          this.locationArray.set(this.trackers[i].imei, 0);
          this.locations.push({
            'lat': null,
            'lng': null,
            'name': null,
            'tag': this.trackers[i].imei
          })
        }
      },
        (err) => {
          if (err.status === 0)
            this._snackBar.open('Server is Down!', 'OK', {
              duration: 2000, verticalPosition: "top"
            });
        });
  }

  showMap(x) {
    var image = '';
    var name = '';
    for (var i = 0; i < x.length; i++) {
      this.lat = x[i].lat;
      this.lng = x[i].lon;
      image = 'data:image/jpeg;base64,' + x[i].animal.picByte;
      name = x[i].animalName + ' Tag Id ' + x[i].imei;
      this.locationArray.set(x[i].imei + "", x[i]);
      this.markers.push({
        'lat': x[i].lat,
        'lng': x[i].lon,
        'image': image,
        'name': name,
        'tag': x[i].imei,
        'info': x[i]
      })
    }
  }

  getMap(x) {
    // localStorage.setItem('tripName', x.tracker.animalname.name);
    localStorage.setItem('trackerId', x.trackerId);
    localStorage.setItem('quick', "no");
    localStorage.setItem('dashboard', "yes");
    // console.log(localStorage.getItem('trackerId'))
    // localStorage.setItem('zoneName', x.zoneName);
    // localStorage.setItem('tripdetails', JSON.stringify(x));
    // this.router.navigate(['/mapview']);
    window.open(AppConstants.ANG_URL + 'mapview', '_blank');
  }

  // google maps zoom level
  zoom: number = 8;

  // initial center position for the map
  // lat: number = 12.903870;
  // lng: number = 77.545710;
  lat: number = 12.2958;
  lng: number = 76.6394;
  previous;

  close_window($event) {
    // alert($event.coords.lat)
    this.zoom = 8;
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    if (this.previous) {
      this.previous.close()
    }
  }

  click($event, info) {
    // alert(info.tracker.animalname.name)
    this.zoom = 10;
    this.lat = info.lat;
    this.lng = info.lon;
    if (this.previous) {
      this.previous.close()
    }
  }

  clickedMarker(label: string, index: number, infowindow) {
    // console.log(`clicked the marker: ${label || index}`)
    this.zoom = 10;
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     draggable: true
  //   });
  // }

  backClick() {
    this.router.navigate(['/tripdetails']);
  }

  mapDragEnd($event: MouseEvent) {
    // console.log('dragEnd', m, $event);
    // alert('hi')
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    // console.log('dragEnd', m, $event);
    // alert('hi')
  }

  // markers: marker[] = [
  //   {
  //     lat: 12.903870,
  //     lng: 77.545710,
  //     label: 'A',
  //     draggable: true
  //   }
  // ]
  markers: marker[] = [];
  locations: location[] = [];
  locationArray: Map<string, number> = new Map<string, number>();;
}


// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  image: string;
  name: string;
  tag: string;
  info: object;
  // label?: string;
  // draggable: boolean;
}

interface location {
  lat: number;
  lng: number;
  name: string;
  tag: string;
}