import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav, MatDrawer } from '@angular/material';
import { AppConstants } from '../AppConstants'; import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.css']
})
export class FullLayoutComponent implements OnInit {
  loogedUserRole: string;
  public kmlURL;
  loogedUOrganization: string;
  constructor(private titleService: Title, private router: Router) {
    // this.kmlURL = AppConstants.KML_URL;
    this.loogedUserRole = atob(localStorage.getItem('roleName'));
    this.loogedUOrganization = atob(localStorage.getItem('organizationName'));
  }
  @ViewChild(MatDrawer, { static: true }) sidenav: MatDrawer;
  isExpanded = true;
  isShowing = false;
  ngOnInit(): void { }

  logout() {
    this.titleService.setTitle('Tracker');
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

  toggle() {
    this.sidenav.toggle();
    this.isExpanded = !this.isExpanded
  }

  mouseenter() {
    // if (!this.isExpanded) {
    //   this.isShowing = true;
    // }
  }

  mouseleave() {
    // if (!this.isExpanded) {
    //   this.isShowing = false;
    // }
  }
}
